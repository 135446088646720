/* Body styles */
body {
    font-family: Arial, sans-serif;
    background-color: #f8f9fa;
    color: #1F1717;
}

/* Error Message */
span {
    color: red;
}


/* Container styles */
.container {
    margin-top: 2rem;
}

/* Card styles */
.card {
    margin-top: 1rem;
}

/* Form styles */
.form-control {
    margin-bottom: 1rem;
}

/* Submit button styles */
.btn-primary {
    background-color: #4CAF50 !important;
    border-color: #4CAF50 !important;
}

.btn-primary:hover {
    background-color: #45a049 !important;
    border-color: #45a049 !important;
}

#registerButton {
    background-color: #1F1717 !important;
    border-color: #1F1717 !important;
}

.black_button {
    background-color: #1F1717 !important;
    border-color: #1F1717 !important;
}

.black_button:hover {
    background-color: #1f1717de !important;
    border-color: #1F1717 !important;
}

.big-font {
    font-size: 40px;
}

.medium-font {
    font-size: 30px;
}

.average-font {
    font-size: 25px;
}

.redirectDashboard {
    margin-right: 60px;
}

#navUser {
    font-size: 20px;
    color:#fff
}

#navUser2 {
    font-size: 20px;
    color:#69fc6e
}